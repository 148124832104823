import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'
import reportWebVitals from './reportWebVitals';
// import {
//   createBrowserRouter,
//   RouterProvider,
// } from "react-router-dom";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router";
import Main from './pages/Main';
import AnimeList from './pages/AnimeList';
// import MySongs from './pages/MySongs';
import '@vidstack/react/player/styles/base.css';

import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import Artist from './pages/Artist';
import Artists from './pages/Artists';

const router = createBrowserRouter([
  // {
  //   path: "/",
  //   element: <Closed />,
  //   errorElement: <ErrorPage />
  // },
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "u/:list/:name",
    element: <AnimeList />,
  },
  // {
  //   path: "artists",
  //   element: <Artists />,
  // },
  // {
  //   path: "artist/:name",
  //   element: <Artist />,
  // },
  // {
  //   path: "si/generator",
  //   element: <SiGenerator />,
  // },
  // {
  //   path: "mysongs",
  //   element: <MySongs />,
  // },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
