import { Button, Card, Label } from "flowbite-react";
import { useState } from "react";
import { customCardTheme } from "../templates/customThemes";

export default function AdvancedParamsComponent({ isAdvancedSearch, setAdvancedParams, fullAnimeList }) {
    const [animeStatusPTW, setAnimeStatusPTW] = useState(true);
    const [animeStatusWatching, setAnimeStatusWatching] = useState(true);
    const [animeStatusCompleted, setAnimeStatusCompleted] = useState(true);
    const [animeStatusOnHold, setAnimeStatusOnHold] = useState(true);
    const [animeStatusDropped, setAnimeStatusDropped] = useState(true);
    const [animeVintageYearMin, setAnimeVintageYearMin] = useState(1944);
    const [animeVintageYearMax, setAnimeVintageYearMax] = useState(2025);
    const [songTypeOP, setSongTypeOP] = useState(true);
    const [songTypeED, setSongTypeED] = useState(true);
    const [songTypeInsert, setSongTypeInsert] = useState(true);
    const [songDifficultyAdvanced, setSongDifficultyAdvanced] = useState(false);
    const [songDifficultyMin, setSongDifficultyMin] = useState(0);
    const [songDifficultyMax, setSongDifficultyMax] = useState(100);
    const [songDifficultyEasy, setSongDifficultyEasy] = useState(true);
    const [songDifficultyMedium, setSongDifficultyMedium] = useState(true);
    const [songDifficultyHard, setSongDifficultyHard] = useState(true);
    const [animeTypeTV, setAnimeTypeTV] = useState(true);
    const [animeTypeMovie, setAnimeTypeMovie] = useState(true);
    const [animeTypeOVA, setAnimeTypeOVA] = useState(true);
    const [animeTypeONA, setAnimeTypeONA] = useState(true);
    const [animeTypeSpecial, setAnimeTypeSpecial] = useState(true);
    const [songCategoryStandard, setSongCategoryStandard] = useState(true);
    const [songCategoryInstrumental, setSongCategoryInstrumental] = useState(true);
    const [songCategoryChanting, setSongCategoryChanting] = useState(true);
    const [songCategoryCharacter, setSongCategoryCharacter] = useState(true);
    const [otherRebroadcast, setOtherRebroadcast] = useState(2);
    const [otherDub, setOtherDub] = useState(2);

    const handleSubmit = (e) => {
        e.preventDefault();

        const newAdvancedParams = {
            "animeStatusPTW": animeStatusPTW,
            "animeStatusWatching": animeStatusWatching,
            "animeStatusCompleted": animeStatusCompleted,
            "animeStatusOnHold": animeStatusOnHold,
            "animeStatusDropped": animeStatusDropped,
            "animeVintageYearMin": animeVintageYearMin,
            "animeVintageYearMax": animeVintageYearMax,
            "songTypeOP": songTypeOP,
            "songTypeED": songTypeED,
            "songTypeInsert": songTypeInsert,
            "songDifficultyAdvanced": songDifficultyAdvanced,
            "songDifficultyEasy": !songDifficultyAdvanced ? songDifficultyEasy : false,
            "songDifficultyMedium": !songDifficultyAdvanced ? songDifficultyMedium : false,
            "songDifficultyHard": !songDifficultyAdvanced ? songDifficultyHard : false,
            "songDifficultyMin": songDifficultyAdvanced && songDifficultyMin,
            "songDifficultyMax": songDifficultyAdvanced && songDifficultyMax,
            "animeTypeTV": animeTypeTV,
            "animeTypeMovie": animeTypeMovie,
            "animeTypeOVA": animeTypeOVA,
            "animeTypeONA": animeTypeONA,
            "animeTypeSpecial": animeTypeSpecial,
            "songCategoryStandard": songCategoryStandard,
            "songCategoryInstrumental": songCategoryInstrumental,
            "songCategoryChanting": songCategoryChanting,
            "songCategoryCharacter": songCategoryCharacter,
            "otherRebroadcast": otherRebroadcast,
            "otherDub": otherDub,
        }

        setAdvancedParams(newAdvancedParams);
    }

    return (
        <div>
            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white text-center mb-2">
                Advanced Search
            </h5>
            <form className="flex flex-wrap gap-2 h-full overflow-y" onSubmit={handleSubmit}>
                {
                    isAdvancedSearch && (
                        <Card theme={customCardTheme} className="shadow-md p-2 w-full">
                            <p className="text-lg text-gray-900 dark:text-white text-center mb-2">Anime Status</p>
                            <div className="flex flex-wrap gap-2">
                                <div className="w-full">
                                    <input type="checkbox" id="animeStatusPTW" checked={animeStatusPTW} onChange={(e) => setAnimeStatusPTW(e.target.checked)} className="hidden peer" />
                                    <label htmlFor="animeStatusPTW" className="flex group items-center group gap-x-1 w-content px-2 py-1 text-gray-500 hover:text-gray-600 peer-checked:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:peer-checked:text-gray-300 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 border-2 border-gray-200 peer-checked:border-gray-500 dark:border-gray-700 rounded-lg cursor-pointer">
                                        <div className="w-full text-sm font-semibold">Plan to Watch</div>
                                        <span className="text-xs font-medium inline-flex items-center px-2 py-0 rounded bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-400">
                                            {
                                                fullAnimeList.filter(anime => anime.animeStatus === "ptw").length
                                            }
                                        </span>
                                    </label>
                                </div>
                                <div className="w-full">
                                    <input type="checkbox" id="animeStatusWatching" checked={animeStatusWatching} onChange={(e) => setAnimeStatusWatching(e.target.checked)} className="hidden peer" />
                                    <label htmlFor="animeStatusWatching" className="flex group items-center group gap-x-1 w-content px-2 py-1 text-gray-500 hover:text-gray-600 peer-checked:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:peer-checked:text-gray-300 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 border-2 border-gray-200 peer-checked:border-gray-500 dark:border-gray-700 rounded-lg cursor-pointer">
                                        <div className="w-full text-sm font-semibold">Watching</div>
                                        <span className="text-xs font-medium inline-flex items-center px-2 py-0 rounded bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-400">
                                            {
                                                fullAnimeList.filter(anime => anime.animeStatus === "watching").length
                                            }
                                        </span>
                                    </label>
                                </div>
                                <div className="w-full">
                                    <input type="checkbox" id="animeStatusCompleted" checked={animeStatusCompleted} onChange={(e) => setAnimeStatusCompleted(e.target.checked)} className="hidden peer" />
                                    <label htmlFor="animeStatusCompleted" className="flex group items-center group gap-x-1 w-content px-2 py-1 text-gray-500 hover:text-gray-600 peer-checked:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:peer-checked:text-gray-300 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 border-2 border-gray-200 peer-checked:border-gray-500 dark:border-gray-700 rounded-lg cursor-pointer">
                                        <div className="w-full text-sm font-semibold">Completed</div>
                                        <span className="text-xs font-medium inline-flex items-center px-2 py-0 rounded bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-400">
                                            {
                                                fullAnimeList.filter(anime => anime.animeStatus === "completed").length
                                            }
                                        </span>
                                    </label>
                                </div>
                                <div className="w-full">
                                    <input type="checkbox" id="animeStatusOnHold" checked={animeStatusOnHold} onChange={(e) => setAnimeStatusOnHold(e.target.checked)} className="hidden peer" />
                                    <label htmlFor="animeStatusOnHold" className="flex group items-center group gap-x-1 w-content px-2 py-1 text-gray-500 hover:text-gray-600 peer-checked:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:peer-checked:text-gray-300 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 border-2 border-gray-200 peer-checked:border-gray-500 dark:border-gray-700 rounded-lg cursor-pointer">
                                        <div className="w-full text-sm font-semibold">On Hold</div>
                                        <span className="text-xs font-medium inline-flex items-center px-2 py-0 rounded bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-400">
                                            {
                                                fullAnimeList.filter(anime => anime.animeStatus === "onhold").length
                                            }
                                        </span>
                                    </label>
                                </div>
                                <div className="w-full">
                                    <input type="checkbox" id="animeStatusDropped" checked={animeStatusDropped} onChange={(e) => setAnimeStatusDropped(e.target.checked)} className="hidden peer" />
                                    <label htmlFor="animeStatusDropped" className="flex group items-center group gap-x-1 w-content px-2 py-1 text-gray-500 hover:text-gray-600 peer-checked:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:peer-checked:text-gray-300 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 border-2 border-gray-200 peer-checked:border-gray-500 dark:border-gray-700 rounded-lg cursor-pointer">
                                        <div className="w-full text-sm font-semibold">Dropped</div>
                                        <span className="text-xs font-medium inline-flex items-center px-2 py-0 rounded bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-400">
                                            {
                                                fullAnimeList.filter(anime => anime.animeStatus === "dropped").length
                                            }
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </Card>
                    )
                }

                <Card theme={customCardTheme} className="shadow-md p-2 w-full">
                    <p className="text-lg text-gray-900 dark:text-white text-center mb-2">Anime Type</p>
                    <div className="flex flex-wrap gap-2">
                        <div className="w-full">
                            <input type="checkbox" id="animeTypeTV" checked={animeTypeTV} onChange={(e) => setAnimeTypeTV(e.target.checked)} className="hidden peer" />
                            <label htmlFor="animeTypeTV" className="flex group items-center group gap-x-1 w-content px-2 py-1 text-gray-500 hover:text-gray-600 peer-checked:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:peer-checked:text-gray-300 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 border-2 border-gray-200 peer-checked:border-gray-500 dark:border-gray-700 rounded-lg cursor-pointer">
                                <div className="w-full text-sm font-semibold">TV</div>
                                <span className="text-xs font-medium inline-flex items-center px-2 py-0 rounded bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-400">
                                    {
                                        fullAnimeList.filter(anime => anime.animeType === 'TV').length
                                    }
                                </span>
                            </label>
                        </div>
                        <div className="w-full">
                            <input type="checkbox" id="animeTypeMovie" checked={animeTypeMovie} onChange={(e) => setAnimeTypeMovie(e.target.checked)} className="hidden peer" />
                            <label htmlFor="animeTypeMovie" className="flex group items-center group gap-x-1 w-content px-2 py-1 text-gray-500 hover:text-gray-600 peer-checked:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:peer-checked:text-gray-300 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 border-2 border-gray-200 peer-checked:border-gray-500 dark:border-gray-700 rounded-lg cursor-pointer">
                                <div className="w-full text-sm font-semibold">Movie</div>
                                <span className="text-xs font-medium inline-flex items-center px-2 py-0 rounded bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-400">
                                    {
                                        fullAnimeList.filter(anime => anime.animeType === 'Movie').length
                                    }
                                </span>
                            </label>
                        </div>
                        <div className="w-full">
                            <input type="checkbox" id="animeTypeOVA" checked={animeTypeOVA} onChange={(e) => setAnimeTypeOVA(e.target.checked)} className="hidden peer" />
                            <label htmlFor="animeTypeOVA" className="flex group items-center group gap-x-1 w-content px-2 py-1 text-gray-500 hover:text-gray-600 peer-checked:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:peer-checked:text-gray-300 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 border-2 border-gray-200 peer-checked:border-gray-500 dark:border-gray-700 rounded-lg cursor-pointer">
                                <div className="w-full text-sm font-semibold">OVA</div>
                                <span className="text-xs font-medium inline-flex items-center px-2 py-0 rounded bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-400">
                                    {
                                        fullAnimeList.filter(anime => anime.animeType === 'OVA').length
                                    }
                                </span>
                            </label>
                        </div>
                        <div className="w-full">
                            <input type="checkbox" id="animeTypeONA" checked={animeTypeONA} onChange={(e) => setAnimeTypeONA(e.target.checked)} className="hidden peer" />
                            <label htmlFor="animeTypeONA" className="flex group items-center group gap-x-1 w-content px-2 py-1 text-gray-500 hover:text-gray-600 peer-checked:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:peer-checked:text-gray-300 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 border-2 border-gray-200 peer-checked:border-gray-500 dark:border-gray-700 rounded-lg cursor-pointer">
                                <div className="w-full text-sm font-semibold">ONA</div>
                                <span className="text-xs font-medium inline-flex items-center px-2 py-0 rounded bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-400">
                                    {
                                        fullAnimeList.filter(anime => anime.animeType === 'ONA').length
                                    }
                                </span>
                            </label>
                        </div>
                        <div className="w-full">
                            <input type="checkbox" id="animeTypeSpecial" checked={animeTypeSpecial} onChange={(e) => setAnimeTypeSpecial(e.target.checked)} className="hidden peer" />
                            <label htmlFor="animeTypeSpecial" className="flex group items-center group gap-x-1 w-content px-2 py-1 text-gray-500 hover:text-gray-600 peer-checked:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:peer-checked:text-gray-300 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 border-2 border-gray-200 peer-checked:border-gray-500 dark:border-gray-700 rounded-lg cursor-pointer">
                                <div className="w-full text-sm font-semibold">Special</div>
                                <span className="text-xs font-medium inline-flex items-center px-2 py-0 rounded bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-400">
                                    {
                                        fullAnimeList.filter(anime => anime.animeType === 'Special').length
                                    }
                                </span>
                            </label>
                        </div>
                    </div>
                </Card>

                <Card theme={customCardTheme} className="shadow-md p-2 w-full">
                    <p className="text-lg text-gray-900 dark:text-white text-center mb-2">Anime Vintage</p>
                    <div className="flex justify-between mb-2">
                        <Label>Vintage</Label>
                        <span className="bg-gray-100 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded ms-2 dark:bg-gray-700 dark:text-gray-400">
                            {
                                fullAnimeList.filter(anime => anime.animeVintage.split(' ')[1] > animeVintageYearMin && anime.animeVintage.split(' ')[1] < animeVintageYearMax).length
                            }
                        </span>
                    </div>

                    <div className="mb-2">
                        <Label htmlFor="animeVintageYearMin" className="w-max">From - {animeVintageYearMin}</Label>
                        <input id="animeVintageYearMin" name="animeVintageYearMin" type="range" step={1} min={1944} max={animeVintageYearMax} value={animeVintageYearMin} onChange={e => setAnimeVintageYearMin(e.target.value)} className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 mb-2" />
                    </div>

                    <div>
                        <Label htmlFor="animeVintageYearMax">To - {animeVintageYearMax}</Label>
                        <input id="animeVintageYearMax" name="animeVintageYearMax" type="range" step={1} max={2025} min={animeVintageYearMin} value={animeVintageYearMax} onChange={e => setAnimeVintageYearMax(e.target.value)} className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 mb-2" />
                    </div>
                </Card>

                <Card theme={customCardTheme} className="shadow-md p-2 w-full">
                    <p className="text-lg text-gray-900 dark:text-white text-center mb-2">Song Type</p>
                    <div className="flex flex-wrap gap-2">
                        <div className="w-full">
                            <input type="checkbox" id="songTypeOP" checked={songTypeOP} onChange={(e) => setSongTypeOP(e.target.checked)} className="hidden peer" />
                            <label htmlFor="songTypeOP" className="flex group items-center group gap-x-1 w-content px-2 py-1 text-gray-500 hover:text-gray-600 peer-checked:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:peer-checked:text-gray-300 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 border-2 border-gray-200 peer-checked:border-gray-500 dark:border-gray-700 rounded-lg cursor-pointer">
                                <div className="w-full text-sm font-semibold">OP</div>
                                <span className="text-xs font-medium inline-flex items-center px-2 py-0 rounded bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-400">
                                    {
                                        fullAnimeList.reduce((res, { songs }) => res + songs.filter(song => song.songType.split(' ')[0] === 'Opening').length, 0)
                                    }
                                </span>
                            </label>
                        </div>
                        <div className="w-full">
                            <input type="checkbox" id="songTypeED" checked={songTypeED} onChange={(e) => setSongTypeED(e.target.checked)} className="hidden peer" />
                            <label htmlFor="songTypeED" className="flex group items-center group gap-x-1 w-content px-2 py-1 text-gray-500 hover:text-gray-600 peer-checked:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:peer-checked:text-gray-300 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 border-2 border-gray-200 peer-checked:border-gray-500 dark:border-gray-700 rounded-lg cursor-pointer">
                                <div className="w-full text-sm font-semibold">ED</div>
                                <span className="text-xs font-medium inline-flex items-center px-2 py-0 rounded bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-400">
                                    {
                                        fullAnimeList.reduce((res, { songs }) => res + songs.filter(song => song.songType.split(' ')[0] === 'Ending').length, 0)
                                    }
                                </span>
                            </label>
                        </div>
                        <div className="w-full">
                            <input type="checkbox" id="songTypeInsert" checked={songTypeInsert} onChange={(e) => setSongTypeInsert(e.target.checked)} className="hidden peer" />
                            <label htmlFor="songTypeInsert" className="flex group items-center group gap-x-1 w-content px-2 py-1 text-gray-500 hover:text-gray-600 peer-checked:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:peer-checked:text-gray-300 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 border-2 border-gray-200 peer-checked:border-gray-500 dark:border-gray-700 rounded-lg cursor-pointer">
                                <div className="w-full text-sm font-semibold">Insert</div>
                                <span className="text-xs font-medium inline-flex items-center px-2 py-0 rounded bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-400">
                                    {
                                        fullAnimeList.reduce((res, { songs }) => res + songs.filter(song => song.songType.split(' ')[0] === 'Insert').length, 0)
                                    }
                                </span>
                            </label>
                        </div>
                    </div>
                </Card>

                <Card theme={customCardTheme} className="shadow-md p-2 w-full">
                    <p className="text-lg text-gray-900 dark:text-white text-center mb-2">Song Difficulty</p>
                    <div className="flex justify-center mb-2">
                        <label className="inline-flex items-center cursor-pointer">
                            <span className="me-3 text-sm font-medium text-gray-900 dark:text-gray-300">Default</span>
                            <input type="checkbox" name="songDifficultyAdvanced" value={songDifficultyAdvanced} onChange={(e) => setSongDifficultyAdvanced(e.target.checked)} className="sr-only peer" />
                            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Advanced</span>
                        </label>
                    </div>
                    {
                        songDifficultyAdvanced ? (
                            <>
                                <div className="flex justify-between mb-2">
                                    <Label>Difficulty</Label>
                                    <span className="bg-gray-100 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded ms-2 dark:bg-gray-700 dark:text-gray-400">
                                        {
                                            fullAnimeList.reduce((res, { songs }) => res + songs.filter(song => song.songDifficulty >= songDifficultyMin && song.songDifficulty <= songDifficultyMax).length, 0)
                                        }
                                    </span>
                                </div>

                                <div className="mb-2">
                                    <Label htmlFor="songDifficultyMin" className="w-max">Min - {songDifficultyMin}</Label>
                                    <input id="songDifficultyMin" name="songDifficultyMin" type="range" step={1} min={0} max={songDifficultyMax} value={songDifficultyMin} onChange={e => setSongDifficultyMin(e.target.value)} className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 mb-2" />
                                </div>

                                <div>
                                    <Label htmlFor="songDifficultyMax">Max - {songDifficultyMax}</Label>
                                    <input id="songDifficultyMax" name="songDifficultyMax" type="range" step={1} max={100} min={songDifficultyMin} value={songDifficultyMax} onChange={e => setSongDifficultyMax(e.target.value)} className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 mb-2" />
                                </div>
                            </>
                        ) : (
                            <div className="flex flex-wrap gap-2">
                                <div className="w-full">
                                    <input type="checkbox" id="songDifficultyEasy" checked={songDifficultyEasy} onChange={(e) => setSongDifficultyEasy(e.target.checked)} className="hidden peer" />
                                    <label htmlFor="songDifficultyEasy" className="flex group items-center group gap-x-1 w-content px-2 py-1 text-gray-500 hover:text-gray-600 peer-checked:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:peer-checked:text-gray-300 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 border-2 border-gray-200 peer-checked:border-gray-500 dark:border-gray-700 rounded-lg cursor-pointer">
                                        <div className="w-full text-sm font-semibold">Easy</div>
                                        <span className="text-xs font-medium inline-flex items-center px-2 py-0 rounded bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-400">
                                            {
                                                fullAnimeList.reduce((res, { songs }) => res + songs.filter(song => song.songDifficulty >= 60 && song.songDifficulty <= 100).length, 0)
                                            }
                                        </span>
                                    </label>
                                </div>
                                <div className="w-full">
                                    <input type="checkbox" id="songDifficultyMedium" checked={songDifficultyMedium} onChange={(e) => setSongDifficultyMedium(e.target.checked)} className="hidden peer" />
                                    <label htmlFor="songDifficultyMedium" className="flex group items-center group gap-x-1 w-content px-2 py-1 text-gray-500 hover:text-gray-600 peer-checked:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:peer-checked:text-gray-300 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 border-2 border-gray-200 peer-checked:border-gray-500 dark:border-gray-700 rounded-lg cursor-pointer">
                                        <div className="w-full text-sm font-semibold">Medium</div>
                                        <span className="text-xs font-medium inline-flex items-center px-2 py-0 rounded bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-400">
                                            {
                                                fullAnimeList.reduce((res, { songs }) => res + songs.filter(song => song.songDifficulty >= 26 && song.songDifficulty <= 59).length, 0)
                                            }
                                        </span>
                                    </label>
                                </div>
                                <div className="w-full">
                                    <input type="checkbox" id="songDifficultyHard" checked={songDifficultyHard} onChange={(e) => setSongDifficultyHard(e.target.checked)} className="hidden peer" />
                                    <label htmlFor="songDifficultyHard" className="flex group items-center group gap-x-1 w-content px-2 py-1 text-gray-500 hover:text-gray-600 peer-checked:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:peer-checked:text-gray-300 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 border-2 border-gray-200 peer-checked:border-gray-500 dark:border-gray-700 rounded-lg cursor-pointer">
                                        <div className="w-full text-sm font-semibold">Hard</div>
                                        <span className="text-xs font-medium inline-flex items-center px-2 py-0 rounded bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-400">
                                            {
                                                fullAnimeList.reduce((res, { songs }) => res + songs.filter(song => song.songDifficulty >= 0 && song.songDifficulty <= 25).length, 0)
                                            }
                                        </span>
                                    </label>
                                </div>
                            </div>
                        )
                    }
                </Card>

                <Card theme={customCardTheme} className="shadow-md p-2 w-full">
                    <p className="text-lg text-gray-900 dark:text-white text-center mb-2">Song Category</p>
                    <div className="flex flex-wrap gap-2">
                        <div className="w-full">
                            <input type="checkbox" id="songCategoryStandard" checked={songCategoryStandard} onChange={(e) => setSongCategoryStandard(e.target.checked)} className="hidden peer" />
                            <label htmlFor="songCategoryStandard" className="flex group items-center group gap-x-1 w-content px-2 py-1 text-gray-500 hover:text-gray-600 peer-checked:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:peer-checked:text-gray-300 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 border-2 border-gray-200 peer-checked:border-gray-500 dark:border-gray-700 rounded-lg cursor-pointer">
                                <div className="w-full text-sm font-semibold">Standard</div>
                                <span className="text-xs font-medium inline-flex items-center px-2 py-0 rounded bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-400">
                                    {
                                        fullAnimeList.reduce((res, { songs }) => res + songs.filter(song => song.songCategory === "Standard").length, 0)
                                    }
                                </span>
                            </label>
                        </div>
                        <div className="w-full">
                            <input type="checkbox" id="songCategoryInstrumental" checked={songCategoryInstrumental} onChange={(e) => setSongCategoryInstrumental(e.target.checked)} className="hidden peer" />
                            <label htmlFor="songCategoryInstrumental" className="flex group items-center group gap-x-1 w-content px-2 py-1 text-gray-500 hover:text-gray-600 peer-checked:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:peer-checked:text-gray-300 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 border-2 border-gray-200 peer-checked:border-gray-500 dark:border-gray-700 rounded-lg cursor-pointer">
                                <div className="w-full text-sm font-semibold">Instrumental</div>
                                <span className="text-xs font-medium inline-flex items-center px-2 py-0 rounded bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-400">
                                    {
                                        fullAnimeList.reduce((res, { songs }) => res + songs.filter(song => song.songCategory === "Instrumental").length, 0)
                                    }
                                </span>
                            </label>
                        </div>
                        <div className="w-full">
                            <input type="checkbox" id="songCategoryChanting" checked={songCategoryChanting} onChange={(e) => setSongCategoryChanting(e.target.checked)} className="hidden peer" />
                            <label htmlFor="songCategoryChanting" className="flex group items-center group gap-x-1 w-content px-2 py-1 text-gray-500 hover:text-gray-600 peer-checked:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:peer-checked:text-gray-300 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 border-2 border-gray-200 peer-checked:border-gray-500 dark:border-gray-700 rounded-lg cursor-pointer">
                                <div className="w-full text-sm font-semibold">Chanting</div>
                                <span className="text-xs font-medium inline-flex items-center px-2 py-0 rounded bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-400">
                                    {
                                        fullAnimeList.reduce((res, { songs }) => res + songs.filter(song => song.songCategory === "Chanting").length, 0)
                                    }
                                </span>
                            </label>
                        </div>
                        <div className="w-full">
                            <input type="checkbox" id="songCategoryCharacter" checked={songCategoryCharacter} onChange={(e) => setSongCategoryCharacter(e.target.checked)} className="hidden peer" />
                            <label htmlFor="songCategoryCharacter" className="flex group items-center group gap-x-1 w-content px-2 py-1 text-gray-500 hover:text-gray-600 peer-checked:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:peer-checked:text-gray-300 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 border-2 border-gray-200 peer-checked:border-gray-500 dark:border-gray-700 rounded-lg cursor-pointer">
                                <div className="w-full text-sm font-semibold">Character</div>
                                <span className="text-xs font-medium inline-flex items-center px-2 py-0 rounded bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-400">
                                    {
                                        fullAnimeList.reduce((res, { songs }) => res + songs.filter(song => song.songCategory === "Character").length, 0)
                                    }
                                </span>
                            </label>
                        </div>
                    </div>
                </Card>

                <Card theme={customCardTheme} className="shadow-md p-2 w-full">
                    <p className="text-lg text-gray-900 dark:text-white text-center mb-2">Other</p>
                    <div className="relative mb-8">
                        <div className="flex justify-between">
                            <Label htmlFor="otherRebroadcast" className="text-">Rebroadcast Songs</Label>
                            <span className="bg-gray-100 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded ms-2 dark:bg-gray-700 dark:text-gray-400">
                                {
                                    fullAnimeList.reduce((res, { songs }) => res + songs.filter(song => song.isRebroadcast === 1).length, 0)
                                }
                            </span>
                        </div>
                        <input id="otherRebroadcast" name="otherRebroadcast" type="range" value={otherRebroadcast} onChange={e => setOtherRebroadcast(parseInt(e.target.value))} min={1} max={3} step={1} className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700" />
                        <span className="text-sm text-gray-500 dark:text-gray-400 absolute start-0 -bottom-6">None</span>
                        <span className="text-sm text-gray-500 dark:text-gray-400 absolute start-1/2 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6">Rebroadcast</span>
                        <span className="text-sm text-gray-500 dark:text-gray-400 absolute end-0 -bottom-6">Only</span>
                    </div>
                    <div className="relative mb-6">
                        <div className="flex justify-between">
                            <Label htmlFor="otherDub" className="text-">Dub Songs</Label>
                            <span className="bg-gray-100 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded ms-2 dark:bg-gray-700 dark:text-gray-400">
                                {
                                    fullAnimeList.reduce((res, { songs }) => res + songs.filter(song => song.isDub === 1).length, 0)
                                }
                            </span>
                        </div>
                        <input id="otherDub" name="otherDub" type="range" value={otherDub} onChange={e => setOtherDub(parseInt(e.target.value))} min={1} max={3} className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700" />
                        <span className="text-sm text-gray-500 dark:text-gray-400 absolute start-0 -bottom-6">None</span>
                        <span className="text-sm text-gray-500 dark:text-gray-400 absolute start-1/2 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6">Dub</span>
                        <span className="text-sm text-gray-500 dark:text-gray-400 absolute end-0 -bottom-6">Only</span>
                    </div>
                </Card>

                <Button type="submit" className="w-full">Submit</Button>
            </form>
        </div>
    )
}
