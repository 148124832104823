import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
// import 'react-h5-audio-player/lib/styles.less' Use LESS
// import 'react-h5-audio-player/src/styles.scss' Use SASS

import { useEffect, useState } from "react";

export const Player = ({ currentSongId, setCurrentSongId, playlist }) => {
    //   <AudioPlayer
    //     // autoPlay
    //     layout="horizontal-reverse" 
    //     src="http://example.com/audio.mp3"
    //     onPlay={e => console.log("onPlay")}
    //     // other props here
    //   />
    const [currentSong, setCurrentSong] = useState(null);
    const [volume, setVolume] = useState(0.3)

    useEffect(() => {
        let currSongIndex = playlist.findIndex(song => song.annSongId === currentSongId);

        let lastIndex = currSongIndex;
        while (!playlist[currSongIndex] || playlist[currSongIndex].audio === null || currSongIndex > playlist.length - 1) {
            if (currSongIndex > playlist.length - 1) {
                currSongIndex = 0;
            } else {
                currSongIndex += 1;
            }

            if (lastIndex === currSongIndex) break;
        }

        setCurrentSong(playlist[currSongIndex])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSongId])

    function NextSong() {
        const currSongIndex = playlist.findIndex(song => song.annSongId === currentSongId);

        let nextSongIndex = currSongIndex + 1;

        if (nextSongIndex > playlist.length - 1) {
            nextSongIndex = 0;
        }

        let lastIndex = nextSongIndex;
        while (!playlist[nextSongIndex] || playlist[nextSongIndex].audio === null || nextSongIndex > playlist.length - 1) {
            if (nextSongIndex > playlist.length - 1) {
                nextSongIndex = 0;
            } else {
                nextSongIndex += 1;
            }

            if (lastIndex === nextSongIndex) break;
        }

        const nextSongId = playlist[nextSongIndex].annSongId;

        setCurrentSongId(nextSongId);
    }
    function PrevSong() {
        const currSongIndex = playlist.findIndex(song => song.annSongId === currentSongId);

        let prevSongIndex = currSongIndex - 1;

        if (prevSongIndex < 0) {
            prevSongIndex = playlist.length - 1;
        }

        let lastIndex = prevSongIndex;
        while (!playlist[prevSongIndex] || playlist[prevSongIndex].audio === null || prevSongIndex > playlist.length - 1) {
            if (prevSongIndex < 0) {
                prevSongIndex = playlist.length - 1;
            } else {
                prevSongIndex -= 1;
            }

            if (lastIndex === prevSongIndex) break;
        }

        const prevSongId = playlist[prevSongIndex].annSongId;

        setCurrentSongId(prevSongId);
    }

    if (playlist && currentSong) {
        return (
            <div className="fixed shadow-lg bottom-0 left-0 z-50 h-18 w-full">
                <AudioPlayer
                    // autoPlay
                    autoPlayAfterSrcChange={true}
                    layout="horizontal-reverse"
                    className='mx-auto'
                    src={
                        currentSong != null ? ((currentSong.audio).indexOf('https://naedist.animemusicquiz.com/') === -1 ? `https://naedist.animemusicquiz.com/${currentSong.audio}` : currentSong.audio) : ('')
                    }
                    showSkipControls={true}
                    showJumpControls={false}
                    onClickNext={NextSong}
                    onClickPrevious={PrevSong}
                    onEnded={NextSong}
                    volume={volume}
                    onVolumeChange={(e) => setVolume(e.volume)}
                    // onPlay={e => console.log("onPlay")}
                    header={
                        <>
                            {
                                currentSong != null && (
                                    <div className='md:flex md:justify-between'>
                                        <div className='pb-2 md:pb-0'>
                                            <span className='text-gray-700 dark:text-gray-200'>
                                                {currentSong.songArtist} - {currentSong.songName}
                                            </span>
                                        </div>
                                        <div>
                                            <span className='text-gray-600 dark:text-gray-300'>
                                                {currentSong.animeJPName} - ({currentSong.songType})
                                            </span>
                                        </div>
                                    </div>
                                )
                            }
                        </>
                    }
                // other props here
                />

                {/* <audio controls
                    src={
                        currentSong != null ? ((currentSong.audio).indexOf('https://naedist.animemusicquiz.com/') === -1 ? `https://naedist.animemusicquiz.com/${currentSong.audio}` : currentSong.audio) : ('')
                    }
                ></audio> */}
            </div>
        )
    }
};