// import { useParams } from "react-router-dom";
import { useParams } from "react-router";
import DefaultLayout from "../templates/DefaultLayout"
import { Card } from "flowbite-react";
import { useEffect, useState } from "react";
import AnimeListComponent from "../components/AnimeListComponent";
import { Player } from "../components/Player";
import { ModalComponent } from "../components/ModalComponent";

import AnimesListData from '../data/animes.json';

export default function AnimeList() {
    const { list, name } = useParams();
    const [pageState, setPageState] = useState('loading');

    const [animeList, setAnimesList] = useState(null);
    const [playlist, setPlaylist] = useState(null);

    const [currentSongId, setCurrentSongId] = useState(null);
    const [currentSongModal, setCurrentSongModal] = useState(null);

    const [showAnimeAdvanced, setShowAnimeAdvanced] = useState(false);
    const [showOnlySavedSongs, setShowOnlySavedSongs] = useState(false);
    const [animesOnPage, setAnimesOnPage] = useState(0);
    const [songsOnPage, setSongsOnPage] = useState(0);

    const [amqSongsList, setAmqSongsList] = useState(null);
    const [artistsList, setArtistsList] = useState(null);
    const [groupsList, setGroupsList] = useState(null);

    function handleAnimeAdvanced() {
        setShowAnimeAdvanced(!showAnimeAdvanced);
    }
    function handleShowOnlySavedSongs() {
        setShowOnlySavedSongs(!showOnlySavedSongs);
    }

    function SortList(data) {
        const order = ['Opening', 'Ending', 'Insert']

        return data.sort((aTitle, bTitle) => aTitle.animeJPName.localeCompare(bTitle.animeJPName))
            .map(anime => {
                let tempAnime = anime;

                tempAnime.songs.sort((aSTitle, bSTitle) => {
                    const aSTitleTemp = aSTitle.songType.split(" ");
                    const bSTitleTemp = bSTitle.songType.split(" ");

                    return order.indexOf(aSTitleTemp[0]) - order.indexOf(bSTitleTemp[0]) || aSTitleTemp[1] - bSTitleTemp[1];
                })

                return tempAnime;
            });
    }

    function GenPlaylist(data) {
        const pl = data.map(anime => anime.songs.map(song => {
            return {
                annSongId: song.annSongId,
                audio: song.audio,
                MQ: song.MQ ? song.MQ : null,
                HQ: song.HQ ? song.HQ : null,
                songArtist: song.songArtist,
                songName: song.songName,
                songType: song.songType,
                animeJPName: anime.animeJPName,
                mal: anime.mal,
            };
        })).flat()

        console.log(pl)

        setPlaylist(pl);
    }

    // function handleUpdate() {
    //     setPageState('loading');

    //     // fetch(`https://api.otakudb.ru/api/update/${list}/${name}`, {
    //         fetch(`http://localhost:5000/api/update/${list}/${name}`, {
    //         method: "POST"
    //     })
    //         .then((response) => response.json())
    //         .then((data) => {
    //             const sortedList = SortList(data);
    //             GenPlaylist(sortedList)
    //             setAnimesList(sortedList);
    //             setPageState('loaded');
    //         }).catch(error => {
    //             setPageState('error');
    //         });
    // }

    function handleUpdate(needUpdate) {
        setPageState('loading');

        setAmqSongsList(Object.keys(AnimesListData.songMap).map((key) => AnimesListData.songMap[key]));
        setArtistsList(Object.keys(AnimesListData.artistMap).map((key) => AnimesListData.artistMap[key]));
        setGroupsList(Object.keys(AnimesListData.groupMap).map((key) => AnimesListData.groupMap[key]));

        // http://localhost:5000/api/anilist/apollon71
        fetch(`https://api.otakudb.ru/api/${list}/${name}`, {
            // fetch(`http://localhost:5000/api/${list}/${name}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                needUpdate: needUpdate
            })
        })
            .then((response) => response.json())
            .then((data) => {
                const sortedList = SortList(data);
                // localStorage.setItem(`animes_${list}_${name}`, JSON.stringify(sortedList));
                GenPlaylist(sortedList)
                setAnimesList(sortedList);
                setPageState('loaded');
            }).catch(error => {
                setPageState('error');
            });
    }

    useEffect(() => {
        handleUpdate(false);
        // // fetch(`https://api.otakudb.ru/api/${list}/${name}`)
        //     fetch(`http://localhost:5000/api/${list}/${name}`)
        //     .then((response) => response.json())
        //     .then((data) => {
        //         const sortedList = SortList(data);
        //         // localStorage.setItem(`animes_${list}_${name}`, JSON.stringify(sortedList));
        //         GenPlaylist(sortedList)
        //         setAnimesList(sortedList);
        //         setPageState('loaded');
        //     }).catch(error => {
        //         setPageState('error');
        //     });
    }, [list, name]);

    if (pageState === 'loading') {
        return (
            <DefaultLayout header={`Loading...`}>
                <div className="p-2">
                    <Card>
                        <h5 className="text-2xl text-center font-bold tracking-tight text-gray-900 dark:text-white">
                            Loading
                        </h5>
                    </Card>
                </div>
            </DefaultLayout>
        );
    } else if (pageState === 'error') {
        return (
            <DefaultLayout header={`Error`}>
                <div className="p-2">
                    <Card>
                        <h5 className="text-2xl text-center font-bold tracking-tight text-gray-900 dark:text-white">
                            Error
                        </h5>
                    </Card>
                </div>
            </DefaultLayout>
        );
    } else if (pageState === 'loaded') {
        return (
            <DefaultLayout header={(
                <div className="flex flex-wrap gap-2 md:justify-between w-full items-center">
                    <div className="flex flex-col md:flex-row md:flex-wrap gap-2 text-center w-full md:w-auto">
                        <span className="w-full md:w-auto">
                            {list === 'mal' ? 'MyAnimeList' : 'Anilist'} - {name}
                        </span>
                        <span className="bg-gray-100 text-gray-800 text-xs font-medium px-2.5 py-1 rounded dark:bg-gray-700 dark:text-gray-300">
                            Total Entries: {
                                animeList.length
                            }
                        </span>
                        <span className="bg-gray-100 text-gray-800 text-xs font-medium px-2.5 py-1 rounded dark:bg-gray-700 dark:text-gray-300">
                            Total Songs: {
                                animeList.map(anime => anime.songs).flat().length
                            }
                        </span>
                        <span className="bg-gray-100 text-gray-800 text-xs font-medium px-2.5 py-1 rounded dark:bg-gray-700 dark:text-gray-300">
                            Animes with Params: {
                                animesOnPage
                            }
                        </span>
                        <span className="bg-gray-100 text-gray-800 text-xs font-medium px-2.5 py-1 rounded dark:bg-gray-700 dark:text-gray-300">
                            Songs with Params: {
                                songsOnPage
                            }
                        </span>
                    </div>
                    <div className="flex gap-2">
                        <div>
                            <input type="checkbox" id="showOnlySavedSongs" value={showOnlySavedSongs} onChange={() => handleShowOnlySavedSongs()} className="hidden peer" />
                            <label htmlFor="showOnlySavedSongs" className="flex group items-center group gap-x-1 w-content px-2 py-1 text-gray-500 hover:text-gray-600 peer-checked:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:peer-checked:text-gray-300 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 border-2 border-gray-200 peer-checked:border-gray-500 dark:border-gray-700 rounded-lg cursor-pointer">
                                <div className="w-full text-sm font-semibold">Show Only Saved Songs</div>
                            </label>
                        </div>
                        <div>
                            <input type="checkbox" id="showAnimeAdvanced" value={showAnimeAdvanced} onChange={() => handleAnimeAdvanced()} className="hidden peer" />
                            <label htmlFor="showAnimeAdvanced" className="flex group items-center group gap-x-1 w-content px-2 py-1 text-gray-500 hover:text-gray-600 peer-checked:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:peer-checked:text-gray-300 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 border-2 border-gray-200 peer-checked:border-gray-500 dark:border-gray-700 rounded-lg cursor-pointer">
                                <div className="w-full text-sm font-semibold">Show Anime Pics</div>
                            </label>
                        </div>
                        <button disabled={pageState === 'loaded' ? false : true} onClick={() => handleUpdate(true)} type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Update</button>
                    </div>
                </div>
            )} playerMargin={true}>
                <div className="p-2">
                    <AnimeListComponent
                        isAdvancedSearch={true}
                        setSongsOnPage={setSongsOnPage}
                        setAnimesOnPage={setAnimesOnPage}
                        setCurrentSongId={setCurrentSongId}
                        animeList={animeList}
                        songsList={playlist}
                        GenPlaylist={GenPlaylist}
                        setCurrentSongModal={setCurrentSongModal}
                        showAnimeAdvanced={showAnimeAdvanced}
                        showOnlySavedSongs={showOnlySavedSongs}
                        amqSongsList={amqSongsList}
                        artistsList={artistsList}
                        groupsList={groupsList}
                    />
                </div>
                <Player
                    currentSongId={currentSongId}
                    setCurrentSongId={setCurrentSongId}
                    playlist={playlist} />
                <ModalComponent
                    currentSongModal={currentSongModal}
                    setCurrentSongModal={setCurrentSongModal}
                    playlist={playlist}
                    artistsList={artistsList}
                    groupsList={groupsList}
                />
            </DefaultLayout>
        );
    }
}
