import { Card } from "flowbite-react";
import { useEffect, useState } from "react";
import AdvancedParamsComponent from "./AdvancedParamsComponent";
import AnimeListWithParamsComponent from "./AnimeListWithParamsComponent";
import { customCardTheme } from "../templates/customThemes";

const defaultAdvancedParams =
{
    "animeStatusPTW": true,
    "animeStatusWatching": true,
    "animeStatusCompleted": true,
    "animeStatusOnHold": true,
    "animeStatusDropped": true,
    'animeVintageYearMin': 1944,
    'animeVintageYearMax': 2025,
    "songTypeOP": true,
    "songTypeED": true,
    "songTypeInsert": true,
    "songDifficultyAdvanced": false,
    "songDifficultyEasy": true,
    "songDifficultyMedium": true,
    "songDifficultyHard": true,
    "songDifficultyMin": 0,
    "songDifficultyMax": 100,
    "animeTypeTV": true,
    "animeTypeMovie": true,
    "animeTypeOVA": true,
    "animeTypeONA": true,
    "animeTypeSpecial": true,
    "songCategoryStandard": true,
    "songCategoryInstrumental": true,
    "songCategoryChanting": true,
    "songCategoryCharacter": true,
    "otherRebroadcast": 2,
    "otherDub": 2,
}

export default function AnimeListComponent({
    isAdvancedSearch,
    setSongsOnPage,
    setAnimesOnPage,
    setCurrentSongId,
    animeList,
    songsList,
    GenPlaylist,
    setCurrentSongModal,
    showAnimeAdvanced,
    showOnlySavedSongs,
    amqSongsList,
    artistsList,
    groupsList
}) {
    const [advancedParams, setAdvancedParams] = useState(defaultAdvancedParams);
    const [fullAnimeList, setFullAnimeList] = useState([]);
    useEffect(() => { }, [advancedParams]);

    return (
        <div className="grid grid-cols-10 gap-2">
            <div className="col-span-10 md:col-span-2 order-1 md:order-2">
                <Card theme={customCardTheme} className="p-2">
                    <AdvancedParamsComponent
                        isAdvancedSearch={isAdvancedSearch}
                        setAdvancedParams={setAdvancedParams}
                        fullAnimeList={fullAnimeList}
                    />
                </Card>
            </div>
            <div className="col-span-10 md:col-span-8 order-2 md:order-1">
                {/* <Card theme={customCardTheme} className="h-full"> */}
                <AnimeListWithParamsComponent
                    isAdvancedSearch={isAdvancedSearch}
                    setSongsOnPage={setSongsOnPage}
                    setAnimesOnPage={setAnimesOnPage}
                    setCurrentSongId={setCurrentSongId}
                    advancedParams={advancedParams}
                    animeList={animeList}
                    GenPlaylist={GenPlaylist}
                    setFullAnimeList={setFullAnimeList}
                    setCurrentSongModal={setCurrentSongModal}
                    showAnimeAdvanced={showAnimeAdvanced}
                    showOnlySavedSongs={showOnlySavedSongs}
                    amqSongsList={amqSongsList}
                    artistsList={artistsList}
                    groupsList={groupsList}
                />
                {/* </Card> */}
            </div>
        </div>
    );
}
