import { Button } from "flowbite-react";
import { useEffect, useState } from "react";

export const AdvancedSearchComponent = ({ search, setSearch }) => {
    const [tempSearch, setTempSearch] = useState({
        'search': search.search,
        'anime': true,
        'title': true,
        'artist': true,
        'composer': true,
        'arranger': true,
    });

    function handleSearch(e, eType) {
        setTempSearch({
            'search': eType === 'search' ? e.target.value : tempSearch.search,
            'anime': eType === 'anime' ? e.target.checked : tempSearch.anime,
            'title': eType === 'title' ? e.target.checked : tempSearch.title,
            'artist': eType === 'artist' ? e.target.checked : tempSearch.artist,
            'composer': eType === 'composer' ? e.target.checked : tempSearch.composer,
            'arranger': eType === 'arranger' ? e.target.checked : tempSearch.arranger,
        })
    }

    function handleAdvancedSearchSubmit(e) {
        e.preventDefault();

        const search = {
            'search': tempSearch.search,
            'anime': tempSearch.anime,
            'title': tempSearch.title,
            'artist': tempSearch.artist,
            'composer': tempSearch.composer,
            'arranger': tempSearch.arranger,
        }

        setSearch(search)
    }

    useEffect(function () {
        setTempSearch({
            'search': search.search,
            'anime': search.anime,
            'title': search.title,
            'artist': search.artist,
            'composer': search.composer,
            'arranger': search.arranger,
        })
    }, [search])

    return (
        <form className="flex flex-wrap gap-2 w-full" onSubmit={handleAdvancedSearchSubmit}>
            <div className="flex w-full">
                <input onChange={(e) => handleSearch(e, 'search')} value={tempSearch.search} type="text" name="search" placeholder="Type to search..." className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-s-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                <Button type="submit" className="rounded-s-none">Search</Button>
            </div>
            <div className="flex justify-between w-full">
                <div className="flex flex-col md:flex-row gap-2 w-full text-center">
                    <div className="w-full md:w-auto">
                        <input onChange={(e) => handleSearch(e, 'anime')} checked={tempSearch.anime} type="checkbox" id="animeAdvancedSearch" className="hidden peer" />
                        <label htmlFor="animeAdvancedSearch" className="flex group items-center group gap-x-1 w-content px-2 py-1 text-gray-500 hover:text-gray-600 peer-checked:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:peer-checked:text-gray-300 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 border-2 border-gray-200 peer-checked:border-gray-500 dark:border-gray-700 rounded-lg cursor-pointer">
                            <div className="w-full text-sm font-semibold">Anime</div>
                        </label>
                    </div>
                    <div className="w-full md:w-auto">
                        <input onChange={(e) => handleSearch(e, 'title')} checked={tempSearch.title} type="checkbox" id="titleAdvancedSearch" className="hidden peer" />
                        <label htmlFor="titleAdvancedSearch" className="flex group items-center group gap-x-1 w-content px-2 py-1 text-gray-500 hover:text-gray-600 peer-checked:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:peer-checked:text-gray-300 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 border-2 border-gray-200 peer-checked:border-gray-500 dark:border-gray-700 rounded-lg cursor-pointer">
                            <div className="w-full text-sm font-semibold">Title</div>
                        </label>
                    </div>
                    <div className="w-full md:w-auto">
                        <input onChange={(e) => handleSearch(e, 'artist')} checked={tempSearch.artist} type="checkbox" id="artistAdvancedSearch" className="hidden peer" />
                        <label htmlFor="artistAdvancedSearch" className="flex group items-center group gap-x-1 w-content px-2 py-1 text-gray-500 hover:text-gray-600 peer-checked:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:peer-checked:text-gray-300 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 border-2 border-gray-200 peer-checked:border-gray-500 dark:border-gray-700 rounded-lg cursor-pointer">
                            <div className="w-full text-sm font-semibold">Artist</div>
                        </label>
                    </div>
                    <div className="w-full md:w-auto">
                        <input onChange={(e) => handleSearch(e, 'composer')} checked={tempSearch.composer} type="checkbox" id="composerAdvancedSearch" className="hidden peer" />
                        <label htmlFor="composerAdvancedSearch" className="flex group items-center group gap-x-1 w-content px-2 py-1 text-gray-500 hover:text-gray-600 peer-checked:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:peer-checked:text-gray-300 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 border-2 border-gray-200 peer-checked:border-gray-500 dark:border-gray-700 rounded-lg cursor-pointer">
                            <div className="w-full text-sm font-semibold">Composer</div>
                        </label>
                    </div>
                    <div className="w-full md:w-auto">
                        <input onChange={(e) => handleSearch(e, 'arranger')} checked={tempSearch.arranger} type="checkbox" id="arrangerAdvancedSearch" className="hidden peer" />
                        <label htmlFor="arrangerAdvancedSearch" className="flex group items-center group gap-x-1 w-content px-2 py-1 text-gray-500 hover:text-gray-600 peer-checked:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:peer-checked:text-gray-300 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 border-2 border-gray-200 peer-checked:border-gray-500 dark:border-gray-700 rounded-lg cursor-pointer">
                            <div className="w-full text-sm font-semibold">Arranger</div>
                        </label>
                    </div>
                </div>
            </div>
        </form>
    )
};