/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Pagination, Popover, Tooltip } from "flowbite-react";
import { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { Link } from "react-router";
import { customCardTheme, customPaginationTheme } from "../templates/customThemes";
import { AdvancedSearchComponent } from "./AdvancedSearchComponent";

function PaginationComponent({
    placement,
    splicedAnimeList,
    maxPages,
    page,
    handleChangePage,
}) {
    return (
        <>
            {
                (splicedAnimeList && maxPages > 1) && (
                    <div className={`flex overflow-x-auto justify-center ${placement === 'top' ? 'pt-2' : 'pb-2'}`}>
                        <Pagination
                            theme={customPaginationTheme}
                            className="table"
                            currentPage={page}
                            totalPages={maxPages}
                            onPageChange={handleChangePage}
                            previousLabel=""
                            nextLabel=""
                            showIcons />
                    </div>
                )
            }
        </>
    )
}

function SaveSongButton({ id, savedSongs, setSavedSongs }) {
    const [saved, setSaved] = useState(false)

    useEffect(function () {
        setSaved(savedSongs.findIndex(sng => sng === id) !== -1)
    }, [id]);

    function handleSaveSongToStorage(id) {
        let tempSavedSongs = savedSongs;

        tempSavedSongs.push(id);

        setSavedSongs(tempSavedSongs);

        localStorage.setItem('songs', JSON.stringify(tempSavedSongs));

        setSaved(true)
    }

    function handleRemoveSongFromStorage(id) {
        let tempSavedSongs = savedSongs;

        tempSavedSongs = tempSavedSongs.filter(ss => ss !== id);

        setSavedSongs(tempSavedSongs);

        localStorage.setItem('songs', JSON.stringify(tempSavedSongs));

        setSaved(false)
    }

    return (
        saved ? (
            <button type="button" onClick={() => handleRemoveSongFromStorage(id)} className="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm p-1 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h14" />
                </svg>
            </button>
        ) : (
            <button type="button" onClick={() => handleSaveSongToStorage(id)} className="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm p-1 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h14m-7 7V5" />
                </svg>
            </button>
        )
    )
}

function SearchWithFilter(search, isAdvancedSearch, animeList, advancedParams, savedSongs, showOnlySavedSongs) {
    const tempSearch = search.search.toLowerCase();

    let newAnimeList = [];

    animeList.forEach(anime => {
        let tempAnime = { ...anime };

        let searchByAnime = false;

        if (isAdvancedSearch) {
            if (
                !(
                    (
                        (advancedParams.animeStatusPTW && tempAnime.animeStatus === 'ptw') ||
                        (advancedParams.animeStatusWatching && tempAnime.animeStatus === 'watching') ||
                        (advancedParams.animeStatusCompleted && tempAnime.animeStatus === 'completed') ||
                        (advancedParams.animeStatusOnHold && tempAnime.animeStatus === 'onhold') ||
                        (advancedParams.animeStatusDropped && tempAnime.animeStatus === 'dropped')
                    )
                    &&
                    (
                        (advancedParams.animeTypeTV && tempAnime.animeType === 'TV') ||
                        (advancedParams.animeTypeMovie && tempAnime.animeType === 'Movie') ||
                        (advancedParams.animeTypeOVA && tempAnime.animeType === 'OVA') ||
                        (advancedParams.animeTypeONA && tempAnime.animeType === 'ONA') ||
                        (advancedParams.animeTypeSpecial && tempAnime.animeType === 'Special')
                    )
                    &&
                    (
                        (tempAnime.animeVintage.split(' ')[1] > advancedParams.animeVintageYearMin) &&
                        (tempAnime.animeVintage.split(' ')[1] < advancedParams.animeVintageYearMax)
                    )
                )
            ) return;
        }

        if (
            (
                tempSearch === ''
            ) || (
                (
                    tempSearch !== '' && search.anime
                ) && (
                    (tempAnime.animeJPName.toLowerCase()).indexOf(tempSearch) !== -1 ||
                    (tempAnime.animeENName.toLowerCase()).indexOf(tempSearch) !== -1 ||
                    (tempAnime.animeAltName && tempAnime.animeAltName.some(altName => (altName.toLowerCase()).indexOf(tempSearch) !== -1))
                )
            )
        ) searchByAnime = true;

        let tempSongs = [];

        tempAnime.songs.forEach(song => {
            if (showOnlySavedSongs) {
                if (savedSongs.findIndex(ss => ss === song.annSongId) === -1) {
                    return
                }
            }

            let tempSong = { ...song }

            if (
                (advancedParams.otherRebroadcast === 1 && tempSong.isRebroadcast === 1) ||
                (advancedParams.otherRebroadcast === 3 && tempSong.isRebroadcast === 0) ||
                (advancedParams.otherDub === 1 && tempSong.isDub === 1) ||
                (advancedParams.otherDub === 3 && tempSong.isDub === 0)
            ) return

            if (
                (!searchByAnime)
                &&
                !(
                    (tempSearch !== '' && search.artist && (tempSong.songArtist.toLowerCase()).indexOf(tempSearch) !== -1) ||
                    (tempSearch !== '' && search.title && (tempSong.songName.toLowerCase()).indexOf(tempSearch) !== -1) ||
                    (tempSearch !== '' && search.artist && (tempSong.artists && tempSong.artists.some(artist => artist.names.some(name => (name.toLowerCase()).indexOf(tempSearch) !== -1)))) ||
                    (tempSearch !== '' && search.composer && (tempSong.composers && tempSong.composers.some(composer => composer.names.some(name => (name.toLowerCase()).indexOf(tempSearch) !== -1)))) ||
                    (tempSearch !== '' && search.arranger && (tempSong.arrangers && tempSong.arrangers.some(arranger => arranger.names.some(name => (name.toLowerCase()).indexOf(tempSearch) !== -1))))
                )
            ) return;

            if (
                !(
                    (
                        (advancedParams.songTypeOP && tempSong.songType.split(" ")[0] === 'Opening') ||
                        (advancedParams.songTypeED && tempSong.songType.split(" ")[0] === 'Ending') ||
                        (advancedParams.songTypeInsert && tempSong.songType.split(" ")[0] === 'Insert')
                    )
                    &&
                    (
                        (
                            (!advancedParams.songDifficultyAdvanced)
                            &&
                            (
                                (advancedParams.songDifficultyEasy && (tempSong.songDifficulty >= 60 && tempSong.songDifficulty <= 100)) ||
                                (advancedParams.songDifficultyMedium && (tempSong.songDifficulty >= 26 && tempSong.songDifficulty <= 59)) ||
                                (advancedParams.songDifficultyHard && (tempSong.songDifficulty >= 0 && tempSong.songDifficulty <= 25))
                            )
                        ) || (
                            (advancedParams.songDifficultyAdvanced)
                            &&
                            (tempSong.songDifficulty >= advancedParams.songDifficultyMin && tempSong.songDifficulty <= advancedParams.songDifficultyMax)
                        )
                    )
                    &&
                    (
                        (advancedParams.songCategoryStandard && tempSong.songCategory === 'Standard') ||
                        (advancedParams.songCategoryInstrumental && tempSong.songCategory === 'Instrumental') ||
                        (advancedParams.songCategoryChanting && tempSong.songCategory === 'Chanting') ||
                        (advancedParams.songCategoryCharacter && tempSong.songCategory === 'Character')
                    )
                )
            ) return;

            tempSongs.push(tempSong);
        });

        if (tempSongs.length > 0) {
            tempAnime.songs = tempSongs;

            newAnimeList.push(tempAnime);
        }
    });

    return newAnimeList;
}

function GroupCard({ artistId, artistsList, groupsList, handleSetSearch }) {
    const artist = artistsList.find(artistL => artistL.songArtistId === artistId);
    const groups = artist ? groupsList.filter(groupL => groupL.artistMembers.findIndex(artistL => artistL === artist.songArtistId) !== -1) : null;

    const getContent = function (artist, groups) {
        return (
            <div className="text-sm text-gray-500 dark:text-gray-400">
                <div className="border-b border-gray-200 bg-gray-100 px-3 py-2 dark:border-gray-600 dark:bg-gray-700">
                    <h3 id="default-popover" className="font-semibold text-gray-900 dark:text-white">
                        Artist {artist.name}
                    </h3>
                </div>
                <div className="px-3 py-2">
                    {
                        (groups.length !== 0 || artist.altNameLinks.length !== 0) ? (
                            <>
                                {
                                    groups.length !== 0 && (
                                        <>
                                            Part of
                                            {
                                                groups.map((group) => (
                                                    <ArtistCard
                                                        groupId={group.songGroupId}
                                                        artistsList={artistsList}
                                                        groupsList={groupsList}
                                                        handleSetSearch={handleSetSearch}
                                                    />
                                                ))
                                            }
                                        </>
                                    )
                                }
                                {
                                    artist.altNameLinks.length > 0 && (
                                        <>
                                            Known as
                                            {
                                                artist.altNameLinks.map((artistAlt) => (
                                                    <GroupCard
                                                        artistId={artistAlt}
                                                        artistsList={artistsList}
                                                        groupsList={groupsList}
                                                        handleSetSearch={handleSetSearch}
                                                    />
                                                ))
                                            }
                                        </>
                                    )
                                }
                            </>
                        ) : (
                            <>None</>
                        )
                    }
                </div>
            </div>
        )
    }

    return (
        (artist && groups) && (
            <div className='flex items-center gap-2'>
                <svg onClick={() => handleSetSearch(artist.name)} className="text-gray-800 dark:text-white cursor-pointer" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z" />
                </svg>
                <div className='w-full'>
                    <Popover
                        aria-labelledby="default-popover"
                        content={getContent(artist, groups)}
                    >
                        <p className='inline-block text-blue-800 dark:text-blue-200 hover:cursor-pointer'>{artist.name}</p>
                    </Popover>
                </div>
            </div>
        )
    )
}

function ArtistCard({ groupId, artistsList, groupsList, handleSetSearch }) {
    const group = groupsList.find(groupL => groupL.songGroupId === groupId);

    const getContent = function (group) {
        return (
            <div className="text-sm text-gray-500 dark:text-gray-400">
                <div className="border-b border-gray-200 bg-gray-100 px-3 py-2 dark:border-gray-600 dark:bg-gray-700">
                    <h3 id="default-popover" className="font-semibold text-gray-900 dark:text-white">
                        Group {group.name}
                    </h3>
                </div>
                <div className="px-3 py-2">
                    {
                        (group.artistMembers.length !== 0 || group.groupMembers.length !== 0 || group.altNameLinks.length !== 0) ? (
                            <>
                                {
                                    group.artistMembers.length > 0 && (
                                        <>
                                            Comprised of
                                            {
                                                group.artistMembers.map((artistM, key) => (
                                                    <GroupCard
                                                        key={key}
                                                        artistId={artistM}
                                                        artistsList={artistsList}
                                                        groupsList={groupsList}
                                                        handleSetSearch={handleSetSearch}
                                                    />
                                                ))
                                            }
                                        </>
                                    )
                                }
                                {
                                    group.groupMembers.length > 0 && (
                                        <>
                                            Same
                                            {
                                                group.groupMembers.map((groupM, key) => (
                                                    <ArtistCard
                                                        key={key}
                                                        groupId={groupM}
                                                        artistsList={artistsList}
                                                        groupsList={groupsList}
                                                        handleSetSearch={handleSetSearch}
                                                    />
                                                ))
                                            }
                                        </>
                                    )
                                }
                                {
                                    group.altNameLinks.length > 0 && (
                                        <>
                                            Alt
                                            {
                                                group.altNameLinks.map((groupM, key) => (
                                                    <ArtistCard
                                                        key={key}
                                                        groupId={groupM}
                                                        artistsList={artistsList}
                                                        groupsList={groupsList}
                                                        handleSetSearch={handleSetSearch}
                                                    />
                                                ))
                                            }
                                        </>
                                    )
                                }
                            </>
                        ) : (
                            <>None</>
                        )
                    }
                </div>
            </div>
        )
    }

    return (
        <div className='flex items-center gap-2'>
            <svg onClick={() => handleSetSearch(group.name)} className="text-gray-800 dark:text-white cursor-pointer" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z" />
            </svg>
            <div className='w-full'>
                <Popover
                    aria-labelledby="default-popover"
                    content={getContent(group)}
                >
                    <p className='inline-block text-blue-800 dark:text-blue-200 hover:cursor-pointer'>{group ? group.name : <>None</>}</p>
                </Popover>
            </div>
        </div>
    )
}

function SongInfoCard({ song, amqSong, artistsList, groupsList }) {
    const getContent = function () {
        return (
            <div className="text-sm text-gray-500 dark:text-gray-400">
                <div className="border-b border-gray-200 bg-gray-100 px-3 py-2 dark:border-gray-600 dark:bg-gray-700">
                    <h3 id="default-popover" className="font-semibold text-gray-900 dark:text-white">Song Info</h3>
                </div>
                <div className="px-2 py-2 bg-white shadow dark:bg-gray-800 text-base">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 pb-2 sm:pb-4 text-center">
                        <div>
                            <p>Song Id: <span className="font-small text-gray-900 whitespace-nowrap dark:text-white">{song.annSongId}</span></p>
                            <p>AMQ Song Id: <span className="font-small text-gray-900 whitespace-nowrap dark:text-white">{song.amqSongId}</span></p>
                        </div>
                        <div>
                            <p>Category: <span className="font-small text-gray-900 whitespace-nowrap dark:text-white">{song.songCategory}</span></p>
                            <p>Difficulty: <span className="font-small text-gray-900 whitespace-nowrap dark:text-white">{song.songDifficulty}</span></p>
                            <p>Length: <span className="font-small text-gray-900 whitespace-nowrap dark:text-white">{song.songLength}</span></p>
                        </div>
                    </div>
                    <div className="flex gap-2">
                        <div className="w-min-80 w-max-80">
                            <p className="bg-gray-100 dark:bg-gray-700 rounded mb-2 text-center">Artist</p>
                            <div>
                                {
                                    amqSong.songArtistId ? (
                                        <div className="bg-gray-100 dark:bg-gray-700 rounded w-full p-2 mt-2">
                                            <GroupCard
                                                artistId={amqSong.songArtistId}
                                                artistsList={artistsList}
                                                groupsList={groupsList}
                                            />
                                        </div>
                                    ) : (
                                        <div className="bg-gray-100 dark:bg-gray-700 rounded w-full p-2 mt-2">
                                            <ArtistCard
                                                groupId={amqSong.songGroupId}
                                                artistsList={artistsList}
                                                groupsList={groupsList}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="w-min-80 w-max-80">
                            <p className="bg-gray-100 dark:bg-gray-700 rounded mb-2 text-center">Composer</p>
                            <div>
                                {
                                    amqSong.composerArtistId ? (
                                        <div className="bg-gray-100 dark:bg-gray-700 rounded w-full p-2 mt-2">
                                            <GroupCard
                                                artistId={amqSong.composerArtistId}
                                                artistsList={artistsList}
                                                groupsList={groupsList}
                                            />
                                        </div>
                                    ) : (
                                        <div className="bg-gray-100 dark:bg-gray-700 rounded w-full p-2 mt-2">
                                            <ArtistCard
                                                groupId={amqSong.composerGroupId}
                                                artistsList={artistsList}
                                                groupsList={groupsList}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="w-min-80 w-max-80">
                            <p className="bg-gray-100 dark:bg-gray-700 rounded mb-2 text-center">Arranger</p>
                            <div>
                                {
                                    amqSong.arrangerArtistId ? (
                                        <div className="bg-gray-100 dark:bg-gray-700 rounded w-full p-2 mt-2">
                                            <GroupCard
                                                artistId={amqSong.arrangerArtistId}
                                                artistsList={artistsList}
                                                groupsList={groupsList}
                                            />
                                        </div>
                                    ) : (
                                        <div className="bg-gray-100 dark:bg-gray-700 rounded w-full p-2 mt-2">
                                            <ArtistCard
                                                groupId={amqSong.arrangerGroupId}
                                                artistsList={artistsList}
                                                groupsList={groupsList}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='w-full'>
            <Popover
                aria-labelledby="default-popover"
                content={getContent()}
            >
                <p className='inline-block text-blue-800 dark:text-blue-200 hover:cursor-pointer'>{amqSong.name}</p>
            </Popover>
        </div>
    )
}

export default function AnimeListWithParamsComponent({
    isAdvancedSearch,
    setSongsOnPage,
    setAnimesOnPage,
    setCurrentSongId,
    advancedParams,
    animeList,
    GenPlaylist,
    setFullAnimeList,
    setCurrentSongModal,
    showAnimeAdvanced,
    showOnlySavedSongs,
    amqSongsList,
    artistsList,
    groupsList,
}) {
    const [animeListWithParams, setAnimeListWithParams] = useState(null);
    const [splicedAnimeList, setSplicedAnimeList] = useState(null);
    const [page, setPage] = useState(1);
    const [maxPages, setMaxPages] = useState(0);
    const [search, setSearch] = useState({
        'search': '',
        'anime': true,
        'title': true,
        'artist': true,
        'composer': true,
        'arranger': true,
    });
    const [savedSongs, setSavedSongs] = useState([]);

    function handleChangePage(page) {
        setPage(page)

        window.scrollTo(0, 0)
    }

    function handleSetSearch(searchTo) {
        setSearch({
            'search': searchTo,
            'anime': false,
            'title': false,
            'artist': true,
            'composer': true,
            'arranger': true,
        })
    }

    useEffect(function () {
        setSavedSongs(localStorage.getItem('songs') ? JSON.parse(localStorage.getItem('songs')) : []);
    }, [animeList])

    useEffect(() => {
        window.scrollTo(0, 0)

        setPage(1);

        const newAnimeList = SearchWithFilter(search, isAdvancedSearch, animeList, advancedParams, savedSongs, showOnlySavedSongs)

        setAnimesOnPage(newAnimeList.length)

        setSongsOnPage(newAnimeList.map(anime => anime.songs).flat().length)

        setFullAnimeList(newAnimeList);

        setMaxPages(Math.floor(newAnimeList.length / 25) + 1);

        if (newAnimeList.length > 0) {
            setAnimeListWithParams(newAnimeList);
        } else {
            setAnimeListWithParams(null);
        }
    }, [animeList, advancedParams, search, showOnlySavedSongs])

    useEffect(() => {
        if (animeListWithParams !== null) {
            const tempAnimeListWithParams = [...animeListWithParams];

            const tempPage = (page - 1) * 25

            const newSplicedAnimeList = tempAnimeListWithParams.splice(tempPage, 25);

            setSplicedAnimeList(newSplicedAnimeList);
            GenPlaylist(newSplicedAnimeList)
        } else {
            setSplicedAnimeList(null);
        }
    }, [animeListWithParams, page])

    useEffect(() => { }, [showAnimeAdvanced, savedSongs])

    return (
        <div className="flex flex-wrap gap-2">
            <Card theme={customCardTheme} className="h-full w-full p-2">
                <AdvancedSearchComponent search={search} setSearch={setSearch} />
            </Card>
            <Card theme={customCardTheme} className="h-full w-full px-2">
                <PaginationComponent
                    placement="top"
                    splicedAnimeList={splicedAnimeList}
                    maxPages={maxPages}
                    page={page}
                    handleChangePage={handleChangePage} />
                <div className="overflow-y-hidden py-2">
                    <div className="w-full h-full overflow-x-scroll sm:overflow-x-hidden overflow-y-hidden flex flex-wrap gap-2">
                        {
                            splicedAnimeList ? (
                                splicedAnimeList.map((anime, k) => (
                                    <Card key={k} theme={customCardTheme} className="shadow-md p-2 w-full">
                                        <div className="w-[1008px] sm:w-full">
                                            <div className="w-full text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                                                <div className="w-full">
                                                    <div className="flex justify-between">
                                                        <div className="flex items-center">
                                                            <p className="inline me-2">{anime.animeJPName}</p>
                                                            <div className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-1 rounded dark:bg-gray-700 dark:text-gray-300">
                                                                {anime.animeType}
                                                            </div>
                                                            <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-1 rounded dark:bg-gray-700 dark:text-gray-300">
                                                                {
                                                                    anime.animeCategory
                                                                }
                                                            </span>

                                                            {
                                                                isAdvancedSearch && (
                                                                    <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-1 rounded dark:bg-gray-700 dark:text-gray-300">
                                                                        {
                                                                            anime.animeStatus === 'ptw' ? 'Plan to Watch' :
                                                                                anime.animeStatus === 'watching' ? 'Watching' :
                                                                                    anime.animeStatus === 'completed' ? 'Completed' :
                                                                                        anime.animeStatus === 'onhold' ? 'On Hold' :
                                                                                            anime.animeStatus === 'dropped' ? 'Dropped' : 'Null'
                                                                        }
                                                                    </span>
                                                                )
                                                            }

                                                            <Popover
                                                                aria-labelledby="default-popover"
                                                                theme={{
                                                                    "base": "absolute z-50 inline-block w-max max-w-[100vw] bg-white outline-none border border-gray-200 rounded-lg shadow-md dark:border-gray-600 dark:bg-gray-800",
                                                                    "content": "z-50 overflow-hidden rounded-[7px]",
                                                                    "arrow": {
                                                                        "base": "absolute h-2 w-2 z-0 rotate-45 mix-blend-lighten bg-white border border-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:mix-blend-color",
                                                                        "placement": "-4px"
                                                                    }
                                                                }}

                                                                content={
                                                                    <div className="text-sm text-gray-500 dark:text-gray-400">
                                                                        <div className="border-b border-gray-200 bg-gray-100 px-3 py-2 dark:border-gray-600 dark:bg-gray-700">
                                                                            <h3 id="default-popover" className="font-semibold text-gray-900 dark:text-white">Anime Info</h3>
                                                                        </div>
                                                                        <div className="px-6 py-2 bg-white shadow dark:bg-gray-800 text-center text-base">
                                                                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                                                                                <div>
                                                                                    <p>Anime Id: <span className="font-small text-gray-900 whitespace-nowrap dark:text-white">{anime.songs[0].annId}</span></p>
                                                                                    <p>Type: <span className="font-small text-gray-900 whitespace-nowrap dark:text-white">{anime.animeType}</span></p>
                                                                                    <p>Season: <span className="font-small text-gray-900 whitespace-nowrap dark:text-white">{anime.animeVintage}</span></p>
                                                                                </div>
                                                                                <div>
                                                                                    <p><Link className="font-medium text-cyan-600 hover:underline dark:text-cyan-500" target="_blank" onClick={() => window.open(`https://myanimelist.net/anime/${anime.mal}`, '_blank', 'noopener,noreferrer')}>MyAnimeList</Link></p>
                                                                                    <p><Link className="font-medium text-cyan-600 hover:underline dark:text-cyan-500" target="_blank" onClick={() => window.open(`https://anilist.co/anime/${anime.anilist}`, '_blank', 'noopener,noreferrer')}>Anilist</Link></p>
                                                                                    <p><Link className="font-medium text-cyan-600 hover:underline dark:text-cyan-500" target="_blank" onClick={() => window.open(`https://kitsu.app/anime/${anime.kitsu}`, '_blank', 'noopener,noreferrer')}>Kitsu</Link></p>
                                                                                    <p><Link className="font-medium text-cyan-600 hover:underline dark:text-cyan-500" target="_blank" onClick={() => window.open(`https://anidb.net/anime/${anime.anidb}`, '_blank', 'noopener,noreferrer')}>AniDB</Link></p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            >
                                                                <Link className="font-medium text-sm text-cyan-600 hover:underline dark:text-cyan-500">
                                                                    Info
                                                                </Link>
                                                            </Popover>
                                                        </div>
                                                        <div className="flex items-center">
                                                            <span className="bg-gray-100 text-gray-800 text-xs font-medium ms-2 px-2.5 py-1 rounded dark:bg-gray-700 dark:text-gray-300">
                                                                OP: {
                                                                    anime.songs.filter(song => song.songType.split(" ")[0] === "Opening").length
                                                                }
                                                            </span>
                                                            <span className="bg-gray-100 text-gray-800 text-xs font-medium ms-2 px-2.5 py-1 rounded dark:bg-gray-700 dark:text-gray-300">
                                                                ED: {
                                                                    anime.songs.filter(song => song.songType.split(" ")[0] === "Ending").length
                                                                }
                                                            </span>
                                                            <span className="bg-gray-100 text-gray-800 text-xs font-medium ms-2 px-2.5 py-1 rounded dark:bg-gray-700 dark:text-gray-300">
                                                                INSERT: {
                                                                    anime.songs.filter(song => song.songType.split(" ")[0] === "Insert").length
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="flex justify-between">
                                                        <div className="flex items-center">
                                                            <p className="text-sm text-gray-500">{anime.animeENName}</p>
                                                        </div>
                                                        <div className="flex items-center">
                                                            <p className="text-sm text-gray-500">
                                                                {
                                                                    anime.animeStatus === 'watching' && (<>Watching</>)
                                                                }
                                                                {
                                                                    anime.animeStatus === 'completed' && (<>Completed</>)
                                                                }
                                                                {
                                                                    anime.animeStatus === 'onhold' && (<>On Hold</>)
                                                                }
                                                                {
                                                                    anime.animeStatus === 'dropped' && (<>Dropped</>)
                                                                }
                                                                {
                                                                    anime.animeStatus === 'ptw' && (<>Plan To Watch</>)
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-full flex gap-2 mt-2">
                                                {
                                                    showAnimeAdvanced && (
                                                        <div className="">
                                                            <div className="w-[200px]">
                                                                <img className="w-full rounded-lg" src={anime.img} alt="" />
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                <div className="w-full">
                                                    <div className="flex flex-wrap gap-2 w-full">
                                                        {
                                                            anime.songs && anime.songs.map((song, j) => {
                                                                const linkedSong = amqSongsList.find(amqSong => amqSong.songId === song.amqSongId)

                                                                return (
                                                                    <div key={j} className="flex gap-2 w-full border-b border-solid border-gray-700 last:border-b-0 pb-2 last:mb-0 last:pb-0">
                                                                        <div className="w-8 w-min-8 w-max-8 flex h-full items-center justify-center" key={j}>
                                                                            <SaveSongButton id={song.annSongId} savedSongs={savedSongs} setSavedSongs={setSavedSongs} />
                                                                        </div>
                                                                        <div className="w-28 w-min-28 w-max-28 flex h-full items-center">
                                                                            {
                                                                                song.songType.split(' ')[0] === 'Opening' ? (
                                                                                    <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-1 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">
                                                                                        {song.songType} {song.isRebroadcast === 1 ? '(R)' : song.isDub === 1 ? '(D)' : ''}
                                                                                    </span>
                                                                                ) : song.songType.split(' ')[0] === 'Ending' ? (
                                                                                    <span className="bg-purple-100 text-purple-800 text-xs font-medium px-2.5 py-1 rounded dark:bg-gray-700 dark:text-purple-400 border border-purple-400">
                                                                                        {song.songType} {song.isRebroadcast === 1 ? '(R)' : song.isDub === 1 ? '(D)' : ''}
                                                                                    </span>
                                                                                ) : (
                                                                                    <span className="bg-pink-100 text-pink-800 text-xs font-medium px-2.5 py-1 rounded dark:bg-gray-700 dark:text-pink-400 border border-pink-400">
                                                                                        {song.songType.split(' ')[0]} {song.isRebroadcast === 1 ? '(R)' : song.isDub === 1 ? '(D)' : ''}
                                                                                    </span>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div className="w-96 w-min-96 w-max-96 flex h-full items-center">
                                                                            <div className="w-full">
                                                                                <SongInfoCard
                                                                                    song={song}
                                                                                    amqSong={linkedSong}
                                                                                    artistsList={artistsList}
                                                                                    groupsList={groupsList}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-1 h-full items-center">
                                                                            <div className="w-full">
                                                                                <div className="flex gap-2 items-center font-small text-wrap text-gray-900 dark:text-white w-full">
                                                                                    {
                                                                                        linkedSong.songArtistId ? (
                                                                                            <GroupCard
                                                                                                artistId={linkedSong.songArtistId}
                                                                                                artistsList={artistsList}
                                                                                                groupsList={groupsList}
                                                                                                handleSetSearch={handleSetSearch}
                                                                                            />
                                                                                        ) : linkedSong.songGroupId ? (
                                                                                            <ArtistCard
                                                                                                groupId={linkedSong.songGroupId}
                                                                                                artistsList={artistsList}
                                                                                                groupsList={groupsList}
                                                                                                handleSetSearch={handleSetSearch}
                                                                                            />
                                                                                        ) : (<>-</>)
                                                                                    }
                                                                                    {/* <Tooltip content="Copied" trigger="click">
                                                                                    <p className="inline-block hover:underline cursor-pointer" onClick={() => navigator.clipboard.writeText(song.songArtist)}>
                                                                                        {song.songArtist}
                                                                                    </p>
                                                                                </Tooltip> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="w-20 w-min-20 w-max-20 flex items-center justify-end">
                                                                            {
                                                                                song.songDifficulty <= 25 ? (
                                                                                    <span className="flex place-self-right place-self-end bg-red-100 text-red-800 text-xs font-medium px-2.5 py-1 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">
                                                                                        Hard
                                                                                    </span>
                                                                                ) : song.songDifficulty <= 60 ? (
                                                                                    <span className="bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-1 rounded dark:bg-gray-700 dark:text-yellow-300 border border-yellow-300">
                                                                                        Medium
                                                                                    </span>
                                                                                ) : (
                                                                                    <span className="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-1 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">
                                                                                        Easy
                                                                                    </span>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div className="w-6 w-min-6 w-max-6 flex h-full items-center justify-center">
                                                                            <Link onClick={(e) => { e.preventDefault(); setCurrentSongModal(song.annSongId) }} className="font-medium text-cyan-600 hover:underline dark:text-cyan-500">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={song.HQ !== null || song.MQ !== null ? `size-6` : `size-6 stroke-red-400`}>
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z" />
                                                                                </svg>
                                                                            </Link>
                                                                        </div>
                                                                        <div className="w-6 w-min-6 w-max-6 flex h-full items-center justify-center">
                                                                            <a href={`https://naedist.animemusicquiz.com/${song.audio}`} target="_blank" className="font-medium text-cyan-600 hover:underline dark:text-cyan-500" rel="noreferrer">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={song.audio !== null ? `size-6` : `size-6 stroke-red-400`}>
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                                                                </svg>
                                                                            </a>
                                                                        </div>
                                                                        <div className="w-6 w-min-6 w-max-6 flex h-full items-center justify-center">
                                                                            <Link onClick={(e) => { e.preventDefault(); setCurrentSongId(song.annSongId) }} className="font-medium text-cyan-600 hover:underline dark:text-cyan-500">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={song.audio !== null ? `size-6` : `size-6 stroke-red-400`}>
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
                                                                                </svg>
                                                                            </Link>
                                                                        </div>
                                                                        {/* border-solid border-b border-gray-700  */}
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                ))
                            ) : (
                                <Card className="w-full text-center">
                                    <h5 className="text-2xl tracking-tight text-gray-900 dark:text-white">Songs Loading or Not Found</h5>
                                </Card>
                            )
                        }
                    </div >
                </div >
                <PaginationComponent
                    placement="bottom"
                    splicedAnimeList={splicedAnimeList}
                    maxPages={maxPages}
                    page={page}
                    handleChangePage={handleChangePage} />
            </Card >
        </div >
    );
}