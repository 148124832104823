import { useEffect, useState } from "react";
import { Modal } from 'flowbite-react';
import { MediaPlayer, MediaProvider } from '@vidstack/react';

export const ModalComponent = ({ currentSongModal, setCurrentSongModal, playlist, artistsList, groupsList }) => {
    const [openModal, setOpenModal] = useState(false);
    const [volume, setVolume] = useState(0.3)
    const [currSong, setCurrSong] = useState(null);
    const [infinity, setInfinity] = useState(true);

    // useEffect(() => {
    //     if (currentSongModal !== null) setOpenModal(true);
    // }, [currentSongModal])

    function CloseModal() {
        setCurrSong(null)
        setOpenModal(false)
        setCurrentSongModal(null)
    }

    useEffect(() => {
        if (currentSongModal === null) return;

        let currSongIndex = playlist.findIndex(song => song.annSongId === currentSongModal);

        // let lastIndex = currSongIndex;
        // while (!playlist[currSongIndex] || ((playlist[currSongIndex].MQ && playlist[currSongIndex].MQ === null) && (playlist[currSongIndex].HQ && playlist[currSongIndex].HQ === null)) || currSongIndex > playlist.length - 1) {
        //     if (currSongIndex > playlist.length - 1) {
        //         currSongIndex = 0;
        //     } else {
        //         currSongIndex += 1;
        //     }

        //     if (lastIndex === currSongIndex) break;
        // }

        setCurrSong(playlist[currSongIndex])
        if (!(openModal)) setOpenModal(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSongModal])

    function NextSong() {
        const currSongIndex = playlist.findIndex(song => song.annSongId === currentSongModal);

        let nextSongIndex = currSongIndex + 1;

        if (nextSongIndex > playlist.length - 1) {
            nextSongIndex = 0;
        }

        // let lastIndex = nextSongIndex;
        // while (!playlist[nextSongIndex] || ((playlist[currSongIndex].MQ && playlist[currSongIndex].MQ === null) && (playlist[currSongIndex].HQ && playlist[currSongIndex].HQ === null)) || nextSongIndex > playlist.length - 1) {
        //     if (nextSongIndex > playlist.length - 1) {
        //         nextSongIndex = 0;
        //     } else {
        //         nextSongIndex += 1;
        //     }

        //     if (lastIndex === nextSongIndex) break;
        // }

        const nextSongId = playlist[nextSongIndex].annSongId;

        setCurrentSongModal(nextSongId);
    }
    function PrevSong() {
        const currSongIndex = playlist.findIndex(song => song.annSongId === currentSongModal);

        let prevSongIndex = currSongIndex - 1;

        if (prevSongIndex < 0) {
            prevSongIndex = playlist.length - 1;
        }

        // let lastIndex = prevSongIndex;
        // while (!playlist[prevSongIndex] || ((playlist[currSongIndex].MQ && playlist[currSongIndex].MQ === null) && (playlist[currSongIndex].HQ && playlist[currSongIndex].HQ === null)) || prevSongIndex > playlist.length - 1) {
        //     if (prevSongIndex < 0) {
        //         prevSongIndex = playlist.length - 1;
        //     } else {
        //         prevSongIndex -= 1;
        //     }

        //     if (lastIndex === prevSongIndex) break;
        // }

        const prevSongId = playlist[prevSongIndex].annSongId;

        setCurrentSongModal(prevSongId);
    }

    return (
        currSong && (
            <Modal show={openModal} onClose={() => CloseModal()} size="3xl">
                <Modal.Header className='p-2'>
                    <p className="text-xl text-gray-900 dark:text-white">
                        {currSong.animeJPName} ({currSong.songType})
                    </p>
                </Modal.Header>
                <Modal.Body className='p-0 flex'>
                    <MediaPlayer
                        controls
                        autoPlay
                        className=""
                        width={"100%"}
                        height={"100%"}
                        volume={volume}
                        onEnd={() => infinity && NextSong()}
                        onVolumeChange={(e) => setVolume(e.volume)}
                        src={`https://naedist.animemusicquiz.com/${currSong.MQ ? currSong.MQ : currSong.HQ}`}
                    >
                        <MediaProvider />
                    </MediaPlayer>
                    {/* <ReactPlayer
                        ref={playerRef}
                        controls
                        volume={0.3}
                        autoPlay
                        width={"100%"}
                        height={"100%"}
                        url={`https://naedist.animemusicquiz.com/${currSong.MQ ? currSong.MQ : currSong.HQ}`} /> */}
                </Modal.Body>
                <Modal.Footer className="p-0">
                    <div className='flex flex-wrap gap-2 p-2 w-full'>
                        <div className='w-full flex gap-2'>
                            <p className="text-xl text-gray-900 dark:text-white">
                                {currSong.songArtist}
                            </p>
                            <p className="text-xl text-gray-900 dark:text-white">
                                -
                            </p>
                            <p className="text-xl text-gray-600 dark:text-gray-400">
                                {currSong.songName}
                            </p>
                        </div>
                        <div className='flex justify-between w-full'>
                            <button type="button" onClick={() => PrevSong()} class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-1.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                                Prev
                            </button>
                            <input type="checkbox" id="infinity" checked={infinity} onChange={() => setInfinity(!infinity)} className="hidden peer" />
                            <label htmlFor="infinity" className="flex group items-center group gap-x-1 w-content px-2 py-1 text-gray-500 hover:text-gray-600 peer-checked:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:peer-checked:text-gray-300 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 border-2 border-gray-200 peer-checked:border-gray-500 dark:border-gray-700 rounded-lg cursor-pointer">
                                <div className="w-full text-sm font-semibold">Infinity</div>
                            </label>
                            <button type="button" onClick={() => NextSong()} class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-1.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                                Next
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal >
        )
    )
};