import { Button, DarkThemeToggle, Flowbite, Footer, Navbar, Popover } from "flowbite-react";
import { customFooterTheme, customNavbarTheme } from "./customThemes";
// import { Link } from "react-router";

export default function DefaultLayout({ header, children, playerMargin }) {
    return (
        <Flowbite className="scroll-smooth">
            <div className="dark:bg-gray-800 min-h-screen">
                <Navbar theme={customNavbarTheme}>
                    <Navbar.Brand href={'/'}>
                        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
                            OtakuDB.Songs
                        </span>
                    </Navbar.Brand>
                    <div className="flex items-center gap-2">
                        <Popover
                            aria-labelledby="default-popover"
                            content={
                                <div className="w-64 text-sm text-gray-500 dark:text-gray-400">
                                    <div className="border-b border-gray-200 bg-gray-100 px-3 py-2 dark:border-gray-600 dark:bg-gray-700">
                                        <h3 id="default-popover" className="font-semibold text-gray-900 dark:text-white">
                                            Top Sponsors
                                        </h3>
                                    </div>
                                    <div className="px-3 py-2">
                                        <p><span className="text-gray-700 dark:text-white font-bold">Apollon</span> - <span className="text-gray-700 dark:text-white">200р</span></p>
                                        <p><span className="text-gray-700 dark:text-white font-bold">Ventila</span> - <span className="text-gray-700 dark:text-white">100р</span></p>
                                    </div>
                                </div>
                            }
                        >
                            <Button size="xs">
                                Sponsors
                            </Button>
                        </Popover>
                        <DarkThemeToggle className="rounded-none hover:text-gray-900 dark:hover:bg-none text-gray-700 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:hover:bg-transparent md:hover:text-cyan-700 md:dark:hover:bg-transparent md:dark:hover:text-white" />
                        <p className="text-gray-700 dark:text-gray-300">v1.3.3</p>
                    </div>
                </Navbar>
                {
                    header && (
                        <div className="bg-gray-50 px-2 py-2 sm:px-4 dark:bg-gray-800 text-gray-800 dark:text-white">
                            <div className="container mx-auto">
                                {header}
                            </div>
                        </div>
                    )
                }
                <main className="bg-gray-200 dark:bg-gray-700">
                    <div className="container mx-auto">
                        {children}
                    </div>
                </main>

                <Footer container theme={customFooterTheme}>
                    <div className="container mx-auto">
                        <div className="justify-between sm:flex sm:items-center sm:justify-between">
                            <Footer.Copyright href={'/'} by="OtakuDB.Songs" year={2024} />
                            {/* <Footer.LinkGroup>
                                <Footer.Link href="#">Anisong</Footer.Link>
                                <Footer.Link href="#">MyAnimeList</Footer.Link>
                                <Footer.Link href="#">Anilist</Footer.Link>
                            </Footer.LinkGroup> */}
                        </div>
                    </div>
                </Footer>
                {playerMargin && (
                    <div className="h-24"></div>
                )}
            </div>
        </Flowbite>
    );
}